import { Box, Text, Stack, MaxWidth } from "@sqymagma/elements"
import { motion } from "framer-motion"
import React from "react"
import Layout from "../components/Layout"
import ReadMoreLink from "../components/ReadMoreLink"
import {
  PlanetLightBlue,
  PlanetDarkBlue,
  PlanetGolden,
  PlanetHiddenSide,
  PlanetTwoHalves,
  OrbitsOnly,
  OrbitsLarge,
} from "../IconSystem"

const NotFoundPage = () => (
  <Layout inverseHeader noFooter>
    <Box
      width="100vw"
      height="100vh"
      minHeight="100vh"
      bg="inverse02"
      overflow="hidden"
      position="relative"
    >
      <motion.div
        style={{
          position: "absolute",
          top: "-10%",
          left: "40%",
          zIndex: 0,
        }}
      >
        <Box position="relative" width="400px" height="400px">
          <OrbitsOnly height="400px" position="absolute" top="0%" left="0%" />
          <PlanetTwoHalves
            height="100px"
            position="absolute"
            top="50%"
            left="50%"
            css={`
              transform: translate(-50px, -50px);
            `}
          />
        </Box>
      </motion.div>
      <MaxWidth
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
        pt="4xl"
      >
        <Stack
          flexDirection="column"
          vs={{ default: "xs", l: "m" }}
          width={{ default: 1, s: 8 / 12, l: 7 / 12 }}
          maxWidth={{ default: "480px", m: "100%" }}
          justifyContent="center"
          zIndex="1"
        >
          <Text as="p" textStyle="tinyTightBold" color="textOnInverse01" caps>
            {`Error 404 – Houston, we have a problem`}
          </Text>{" "}
          <Text as="h1" mb="0" textStyle="display01" color="textOnInverse04">
            Perdido en el espacio
          </Text>
          <Text
            as="p"
            pt="15px"
            textStyle="title04"
            color="textOnInverse03"
            mb={{ default: "l", xs: "xl", m: "l" }}
          >
            Parece que te has desviado de la órbita prevista. Vuelve a la base para continuar
            explorando.
          </Text>
          <ReadMoreLink to="/" label="Volver al inicio" mb="l" color="textOnInverse01" />
        </Stack>
      </MaxWidth>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 60 }}
        style={{
          position: "absolute",
          bottom: "-35%",
          left: "25vw",
          originX: "225px",
          originY: "225px",
        }}
      >
        <PlanetLightBlue height="450px" />
      </motion.div>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 60, type: "tween" }}
        style={{
          position: "absolute",
          top: "15%",
          right: "10%",
          originX: "-225px",
          originY: "225px",
          zIndex: 2,
        }}
      >
        <PlanetGolden height="50px" />
      </motion.div>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 60, type: "tween" }}
        style={{
          position: "absolute",
          top: "20%",
          right: "20%",
          originX: "-300px",
          originY: "200px",
          zIndex: 2,
        }}
      >
        <PlanetDarkBlue height="50px" />
      </motion.div>
      <motion.div
        style={{
          position: "absolute",
          bottom: "20%",
          right: "20%",
          originX: "-300px",
          originY: "200px",
        }}
      >
        <PlanetHiddenSide height="200px" />
      </motion.div>
      <motion.div
        style={{
          position: "absolute",
          top: "0%",
          right: "-5%",
          originX: "-300px",
          originY: "200px",
          opacity: 0.15,
        }}
      >
        <OrbitsLarge height="100vh" />
      </motion.div>
    </Box>
  </Layout>
)
export default NotFoundPage
